import { action, computed, makeObservable, observable } from 'mobx';
import { contractApi } from '@link/gds-supplier/services';
import { ContractSummary } from '@link/gds-supplier/services/types';

export class Contract {
  nextCursor = '';
  loading = false;
  supplierId = '';
  @observable accessor contracts: Array<ContractSummary> = [];

  constructor() {
    makeObservable(this);
  }

  @action
  fetchContracts = async (supplierId: string) => {
    const response = await contractApi.listContracts({
      body: {
        supplierId,
        cursor: this.nextCursor
      }
    });
    const { contracts, nextCursor = '' } = response;
    if (contracts) {
      this.addContracts(contracts);
    }
    this.setCursor(nextCursor);
    this.setSupplierId(supplierId);
    return response;
  };

  @action
  fetchAllContracts = async (supplierId: string) => {
    if (!this.loaded && !this.loading) {
      this.loading = true;
      const { nextCursor } = await this.fetchContracts(supplierId);
      this.loading = false;
      if (nextCursor) {
        await this.fetchAllContracts(supplierId);
      }
    }
  };

  @action
  clearContracts = () => {
    this.contracts = [];
    this.nextCursor = '';
  };

  @action
  setSupplierId = (supplierId: string) => {
    this.supplierId = supplierId;
  };

  @action
  addContracts = (contracts: Array<ContractSummary>) => {
    this.contracts = this.contracts.concat(contracts);
  };

  @action
  setCursor = (cursor: string) => {
    this.nextCursor = cursor;
  };

  @computed
  get contractOtaIds() {
    return this.contracts.map(contract => contract.otaId || '');
  }

  @computed
  get loaded() {
    return this.contracts.length > 0 && this.nextCursor === '';
  }
}

export default new Contract();
