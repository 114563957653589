import { action, computed, makeObservable, observable } from 'mobx';
import { activityApi } from '@link/gds-supplier/services';
import activity from './activity';
export class Memo {
  @observable accessor memo = '';
  @observable accessor tags: string[] = [];
  constructor() {
    makeObservable(this);
  }

  @action
  getMemo = async (activityId: string) => {
    const memo = await activityApi.getMemo({
      body: {
        activityTemplateId: activityId
      }
    });
    if (memo) {
      this.setMemo(memo.memo ?? '');
      this.setTags(memo.tags ?? []);
    }
  };

  @action
  setMemo = (memo: string) => {
    this.memo = memo;
  };

  @action
  setTags = (tags: string[]) => {
    this.tags = tags;
  };

  @action
  updateMemo = async (
    payload: { memo?: string; tags?: string[] },
    templateId?: string
  ) => {
    const activityId = templateId ?? activity.activityId;
    await activityApi.updateMemo({
      body: {
        activityTemplateId: activityId,
        memo: payload.memo,
        tags: payload.tags
      }
    });
    // update store
    this.setMemo(payload.memo ?? '');
    this.setTags(payload.tags ?? []);
  };
  @computed
  get showMemo() {
    return !!this.memo;
  }

  @computed
  get showTags() {
    return !!this.tags.length;
  }
}

export default new Memo();
