export default {
  form: {
    id: '予約情報ID',
    title: '予約情報タイトル',
    info: '予約情報説明',
    setting: '予約情報設定方法',
    deadline: '締切種類',
    answerType: '回答形式',
    selectItems: '選択項目',
    selectPrompt: '選択してください'
  },
  rule: {
    type: '予約タイプ',
    possibleInterval: '予約可能期間',
    interval: '予約期限',
    daysBefore: '日前',
    timeBefore: '時まで'
  },
  cancel: {
    notCancellable: 'ご予約後のキャンセル・変更は受け付けておりません。',
    freeCancellable:
      '引換前まで無手数料で取消可。引換後、払戻は一切不可になります。',
    flex: '高度なキャンセルポリシーを設定する。',
    preview: 'プレビュー',
    cancelPreview: 'キャンセルポリシープレビュー',
    info: 'カスタム説明文',
    timeSetting: 'キャンセル日時設定',
    day: '日{D}',
    timeFormat: '時間{HH:MM}',
    time: '時間{T}',
    feeSetting: 'キャンセル料の設定',
    feeSettingPrompt: 'キャンセル料設定を選んでください',
    cancelPolicyPrompt: 'キャンセルポリシーを選択してください',
    priceReferenceSale: '価格基準：販売価格',
    priceReference: '価格基準：',
    fromClient: '旅行者から (%)',
    fromAgent: '旅行代理店から (%)',
    toSupplier: '催行会社に (%)',
    paymentCancelFee: 'キャンセル料のお支払い',
    fromClientToAgent: '旅行者から代理店へ',
    fromAgentToLinktivity: '代理店からLINKTIVITYへ',
    fromLinktivityToSupplier: 'LINKTIVITYから催行会社へ'
  },
  checklist: {
    notice: '注意事項 {{index}}',
    selectNoticePrompt: '参加前・参加時の注意事項を選んでください',
    uploadPDF: 'PDFアップロード',
    uploadImage: '画像アップロード',
    googleMapSetting: 'Google Map設定'
  },
  faq: {
    question: '質問',
    answer: '回答',
    faqTitle: '項目 {{index}}'
  },
  itinerary: {
    title: 'タイトル',
    detailedSchedule: 'スケジュール詳細'
  },
  startTime: {
    id: 'ID',
    name: '開始時間',
    title: 'タイトル',
    poolId: '在庫 ID'
  },
  location: {
    title: '{{label}}名称',
    address: '住所',
    placeId: 'Place ID',
    latlong: '緯度経度',
    select: '{{label}}選択',
    errMsg:
      '検索対象が見つかりません。入力内容を再確認するか、またはマップから直接選択してください。'
  },
  price: {
    info: '価格 {{index}}',
    reservableInterval: '予約可能期間',
    startTime: '開始時間',
    endTime: '終了時間',
    unitType: 'ユニット種類',
    unitPrice: '販売単価',
    soldPrice: '販売価格',
    agentPrice: '卸価格',
    comission: 'コミッション',
    price: '料金',
    accuracy: '精度',
    rate: 'レート',
    comissionRate: 'コミッションレート（表示上）',
    comissionRateTips:
      '明細・帳票等にレートを表する項目はこちらのレートが表示されます',
    auto: 'レート精度設定',
    applicableSetting: '適用期日設定',
    applicableInterval: '適用期間',
    applicableDates: '適用曜日',
    otherApplicableDates: 'その他適用日',
    unitRange: '単位範囲',
    standardPrice: '標準価格',
    numberIncluded: '定員に含む',
    soloPurchase: '単独購入'
  },
  voucher: {
    incrementDate: '年の増分基準日'
  },
  backendBundle: {
    backendOption: 'Backend',
    bundleOption: 'Bundle',
    backendId: 'Backend在庫ID',
    bundleChildAllotmentPoolId: '関連子商品在庫ID',
    parentStartTimeId: '開始時間在庫ID',
    refTime: '参考時間',
    childStartTimeId: '子商品開始時間在庫ID',
    addPrompt: '関連子商品ID追加',
    label: 'Backend選択',
    bundleStartTimeSetting: '開始時間ID設定',
    segmentTime: '開始時間ID',
    childSegmentTimeId: '子商品開始時間ID',
    backendAllotment: 'Backend在庫設定',
    textPlaceholder: 'IDを選択してください',
    bundleAddPrompt: 'Bundle用在庫設定追加',
    bundleGroupHeaderText: '関連子商品ID'
  },
  backendForm: {
    cloudSchedulerJob: '同期ID',
    state: '状態',
    status: 'ステータス',
    lastExecution: '前回更新',
    nextExecution: '次回更新',
    selectSupplier: 'サプライヤー選択',
    selectSupplierPlaceholder: '催行会社を選択してください',
    name: '管理名',
    backendPoolId: 'Backend在庫管理ID',
    backend: 'Backend情報',
    selectBackendPlaceholder: '連携するBackendを選択してください',
    supplierPoolId: 'Supplier Pool ID',
    scheduleTimezone: '更新タイムゾーン',
    schedule: '更新スケジュール',
    scheduleNote: '※記載方法のはこちらを参考してください'
  },
  allotmentForm: {
    supplierId: '催行会社名',
    selectSupplierPlaceholder: '催行会社を選択してください',
    id: '在庫ID',
    name: '在庫管理名',
    freesale: 'フリーセール',
    copyCalendarEdits: 'カレンダー編集コピー'
  },
  dateSetting: {
    titlePlaceholder: '在庫管理名を入力してください',
    name: '基本在庫設定',
    interval: '適用期間（曜日）',
    exactDates: '個別適用日',
    commonAllotment: '在庫設定',
    add: '基本在庫追加',
    common: 'Common',
    freesale: 'フリーセール',
    createTitle: '在庫設定追加',
    modal: {
      interval: '適用期間',
      dates: '適用曜日',
      otaAllotments: '代理店在庫設定'
    }
  },
  exception: {
    name: '例外開始時間設定',
    default: 'デフォルト在庫',
    allowedDefault: '利用可能',
    disallowedDefault: '利用不可',
    defaultApplicable: '(基本在庫設定適用: {{applicable}})',
    applicable: '適用する',
    notApplicable: '適用しない'
  },
  segmentId: {
    name: '開始時間ID',
    addPrompt: '開始時間ID追加',
    refTime: '参考時間',
    startTimeIdRequired: '開始時間IDを入力してください',
    startTimeRequired: '開始時間を入力してください'
  },
  otaGroup: {
    name: '代理店在庫グループ設定',
    groupName: '代理店在庫グループ名',
    addPrompt: '代理店在庫設定追加'
  },
  calendar: {
    groupRule: '在庫表示',
    week: '週',
    month: '月',
    showMore: '詳しく',
    amountReserved: '実際予約数',
    amountLimit: '最大予約数',
    amountBackend: 'Supplier在庫'
  },
  editAllotment: {
    type: '種類',
    amountReserved: '予約済',
    amountEdited: '在庫枠',
    amountRemains: '残在庫数',
    shared: '共有在庫'
  }
};
