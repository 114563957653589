export default {
  common: {
    activityId: '商品ID',
    yes: 'あり',
    no: 'なし',
    edit: '編集',
    close: '閉じる',
    editConfirm: '編集の確認',
    successCreatedMsg: '{{name}}追加に成功しました',
    errorCreatedMsg: '{{name}}追加に失敗しました',
    successDeletedMsg: '{{name}}削除に成功しました',
    errorDeletedMsg: '{{name}}削除に失敗しました',
    successUpdatedMsg: '{{name}}更新に成功しました',
    errorUpdatedMsg: '{{name}}更新に失敗しました'
  },
  dashboard: 'Dashboard',
  activities: {
    name: '商品名',
    title: '商品名',
    edit: '編集',
    confirmed: '商品確認成功しました',
    status: 'ステータス',
    list: '商品一覧',
    addActivity: '商品登録',
    addNewActivity: '新規商品登録',
    add: '登録',
    selectSupplier: '催行会社を選択してください',
    inputSupplierTitle: '商品名を入力してください',
    copyTitle: '商品コピー',
    selectSupplierLabel: 'Supplier選択',
    copy: 'コピー'
  },
  allotmentSettings: {
    pageTitle: '営業日・在庫設定',
    title: '在庫設定',
    management: '在庫登録',
    poolId: '管理ID',
    supplierId: '催行会社名',
    name: '在庫管理名',
    sharedAllotment: '共有在庫',
    inputAllotmentName: '在庫管理名を入力してください',
    leaveCurrentPageConfirm: `在庫設定はまだ編集中です
      このページから移動してもよろしいですか？`,
    leaveCurrentPage: 'このページを離れる',
    continueEditing: '編集を続ける',
    edit: {
      name: '在庫',
      createTitle: '新規在庫登録',
      copyTitle: '在庫コピー'
    }
  },
  backendSettings: {
    title: 'Backend在庫設定',
    newTitle: '新規Backend在庫設定',
    management: '在庫登録',
    poolId: '管理ID',
    supplierId: '催行会社名',
    name: '管理名',
    confirm: '確定',
    state: '状態',
    status: 'ステータス',
    lastExecution: '前回更新',
    nextExecution: '次回更新',
    sharedAllotment: '共有在庫',
    jobStatus: {
      success: 'Success',
      failed: 'Failed'
    }
  },
  activity: {
    log: {
      logHistory: '商品編集履歴',
      priceHistory: '価格グループ編集履歴',
      chooseLog: '履歴選択',
      chooseItemLabel: 'プラン {{index}} 編集履歴',
      html: 'HTML Log',
      by: 'by'
    },
    memo: {
      title: '編集用メモ',
      tips: '編集用メモは商品編集時に記録用のもので、代理店または直販サイト上では表示されません。',
      successUpdated: 'メモ保存しました'
    },
    tag: {
      title: '編集用タグ',
      tips: '編集用タグは商品編集時に記録用のもので、代理店または直販サイト上では表示されません。'
    },
    paymentCollector: '集金者',
    productDescription: '商品説明',
    priceSetting: '通貨設定',
    autoPublic: '自動公開設定',
    autoPublicGroup: '対象グループ選択',
    imageSetting: '画像設定',
    participantNotice: '参加前・参加時の注意事項（含まれるものなど）',
    agentGroupName: '価格グループ名',
    agentSaved: '代理店保存しました',
    agentSetting: '価格グループ設定',
    addAgentGroup: '価格グループを追加',
    agentTips:
      '※価格グループは代理店ごとに価格設定と連携しているため、1つの旅行代理店を複数のグループに追加できません。',
    comment: 'コメント',
    publishedApplied: '公開申請しました。',
    rejectApplied: '差し戻しました。',
    approved: '承認しました。',
    published: '公開しました。',
    publish: '公開',
    reject: '差し戻し',
    approve: '公開承認',
    submit: '公開申請',
    save: '保存',
    preview: 'プレビュー',
    applyForPublish: '商品公開申請',
    productConfirmationMessage:
      'ご登録した商品の内容とプランをご確認の上、商品公開してください。',
    confirmReject: '承認差し戻し',
    confirmApprovalOfProduct: '商品公開承認',
    productContentConfirmed: '商品の内容は確認いたしました。',
    confirmForPublishingProducts: '商品公開確認',
    productPublishedConfirmed: '商品公開承認いたしました。',
    productSaved: '商品保存しました',
    languageSetting: '多言語設定',
    languageSelection: '言語選択',
    productTitle: '商品名（バウチャータイトル）',
    origin: '出発地',
    destination: '目的地',
    checkinPoints: '集合場所',
    checkoutPoints: '解散場所',
    pickupLocation: 'ピックアップ場所',
    dropoffLocations: 'お送り場所',
    cancellationPolicy: 'キャンセルポリシー',
    faqSetting: 'FAQ設定',
    chosenLanguage: '掲載言語',
    timeZone: '国・地域（タイムゾーン）',
    category: 'カテゴリ',
    useQrCode: 'QRコード利用',
    qrcodeType: 'QRCodeType',
    voucherSetting: 'バウチャー設定',
    appointmentSetting: '予約設定',
    appointmentNotification: '予約通知',
    qrCodeTips:
      '※『人数別でQRコード』または『モギリ利用』の設定は弊社の担当へご連絡ください。',
    useEticketItems: 'E-TICKET利用項目',
    qrcodeTemplate: 'QRコードテンプレート',
    qrcodeHeader: 'QRコードヘッダー',
    qrcodeFooter: 'QRコードフッター',
    qrcodeGenerator: 'QRコードジェネレーター',
    redemptionUrlTemplate: '引換URLテンプレート',
    adminSettingSaved: 'Admin設定保存しました',
    allotmentSettingSaved: 'プラン設定保存しました',
    planSelection: 'プラン選択',
    planId: 'プランID',
    systemCharge: 'システム料金',
    productInfo: '商品情報',
    supplierId: '催行会社ID',
    supplierName: '催行会社名',
    name: '商品名',
    editMemo: '編集用メモ',
    editTag: '編集用タグ',
    publishActivityConfirm: 'こちらの商品公開しますか？',
    publishActivitySuccess:
      '※商品公開後、設定した代理店側が商品の情報を閲覧できる状態になります。',
    inPublish: '公開中',
    isActivityPublished: '商品公開しました',
    isActivityPublishedFailed: '商品の公開が失敗しました',
    contactUs: 'お手数をおかけしますが、担当者へご連絡ください。',
    leaveCurrentPageConfirm: `商品はまだ編集中です
      このページから移動してもよろしいですか？`,
    leaveCurrentPage: 'このページを離れる',
    continueEditing: '編集を続ける',
    salesStatus: '商品表示',
    hideFromSearch: '一覧表示',
    salesStatusNotes:
      '※商品の表示状態を変更する場合、再度公開する必要がございますので、ご注意ください。',
    hideFromSearchNotes:
      '※一覧表示をオフにした場合、検索結果または商品一覧上には表示されませんので、ご注意ください。'
  },
  plan: {
    saveConfirm: 'プラン保存しました',
    name: 'プラン名',
    save: '保存',
    planPublish: 'プラン掲載表示',
    bookingRule: '予約設定',
    voucherExpiration: 'バウチャー有効期限',
    expirationVisibilitySetting: 'バウチャー有効期限を隠す',
    visibilitySetting: {
      hideFromVoucher: 'バウチャーに隠す',
      hideFromActivityDetails: 'ウェブページに隠す（商品内容）',
      hideFromBookingDetails: 'ウェブページに隠す（予約詳細）'
    },
    checkinPoint: '集合場所',
    checkoutPoint: '解散場所',
    pickupLocation: 'ピックアップ場所',
    dropoffLocation: 'お送り場所',
    unitSetting: 'ユニット設定',
    priceSetting: '料金設定',
    planPublishNotes:
      '※プランの表示状態を変更する場合、再度公開する必要がございますので、ご注意ください。',
    bookingAgentRequiredInfo: '代表者情報-予約ごと（必須）',
    bookingAgentRecommendedInfo: '代表者情報-予約ごと（任意）',
    bookingOtherRequiredInfo: 'その他情報-予約ごと（必須）',
    bookingOtherRecommendedInfo: 'その他情報-予約ごと（任意）',
    participantRequiredInfo: '利用者情報-参加者ごと（必須）',
    participantRecommendedInfo: '利用者情報-参加者ごと（任意）',
    customBookingInfo: 'カスタマイズ予約情報',
    settingUnable: '終了日を開始日の前に設定することはできません',
    description: 'プラン説明',
    imageSetting: '画像設定',
    availablePeriod: '公開期間',
    reservationRestrict: '予約数量制限',
    pieces: '枚',
    min: '最小',
    max: '最大',
    tilde: '〜',
    checklistLabel: '参加前・参加時の注意事項（含まれるものなど）',
    startTime: '開始時間',
    setSchedule: 'スケジュール設定',
    editConfirm: '編集の確認',
    leavePrompt: `プランはまだ編集中です
      このページから移動してもよろしいですか？`,
    leave: 'このページを離れる',
    continueEditing: '編集を続ける',
    code: {
      id: 'プランID',
      coupon: 'Bundle子商品のバウチャーを隠す',
      backendId: '在庫設定ID',
      backendIdLabel: '在庫設定を選択してください',
      goToSetting: '未設定の場合は<0>こちら</0>から設定してください',
      backend: 'backend情報',
      backendLabel: '連携するBackendを選択してください',
      productCode: 'Product Code',
      productCodeLabel: 'Product Codeを記入してください',
      unit: 'Unit設定',
      unitType: 'ユニット種類',
      unitRange: '単位範囲',
      unitCode: 'Unit Code',
      startTimeTitle: '開始時間設定',
      startTime: '開始時間',
      startTimeCode: 'Start time Code',
      codeLabel: 'Codeを記入してください'
    }
  },
  login: {
    title: 'Welcome to GDS',
    email: 'メールアドレス',
    password: 'パスワード',
    signIn: 'ログイン',
    forgotPassword: 'パスワードをお忘れの場合',
    googleLogin: 'Googleでログイン'
  },
  password: {
    changePassword: 'パスワード変更',
    resetPassword: 'パスワード再設定',
    sendResetEmail: 'パスワードリセットメールを送信する',
    sendResetEmailSuccess:
      'パスワード再設定用のURLが{{email}}に送信されます、ご確認のうえパスワードをリセットしてください。',
    oldPassword: '以前のパスワード',
    newPassword: '新しいパスワード',
    changePasswordSuccess: 'パスワードを変更しました。'
  }
};
