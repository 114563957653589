import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { activityApi, pricingApi } from '@link/gds-supplier/services';
import { SalesStatus } from '@link/gds-supplier/services/const';
import {
  PlanTemplate,
  EditPlanTemplateReqPayload,
  PlanPriceSetting
} from '@link/gds-supplier/services/types';
import activity from './activity';
import { AdministrativePlanFields } from '@link/gds-supplier/services/types';

export class Plan {
  @observable accessor plan: PlanTemplate | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  setPlan = (plan: PlanTemplate) => {
    this.plan = plan;
  };

  @action
  updatePlan = async (payload: EditPlanTemplateReqPayload) => {
    const plan = await activityApi.editPlanTemplate({
      body: {
        planTemplateId: this.planId,
        payload: { ...this.plan, ...payload },
        planVersion: this.version
      }
    });
    if (plan) {
      this.setPlan(plan);
      await activity.reloadActivity();
    }
  };
  @action
  updateAdminFields = async (payload: AdministrativePlanFields) => {
    const plan = await activityApi.editPlanAdministrativeFields({
      body: {
        planTemplateId: this.planId,
        payload: { ...payload },
        planVersion: this.version
      }
    });
    if (plan) {
      this.setPlan(plan);
      await activity.reloadActivity();
    }
  };
  @action
  updatePriceInfo = async (payload: PlanPriceSetting) => {
    const plan = await pricingApi.editPlanPriceInfo({
      body: {
        planTemplateId: this.planId,
        payload: { ...this.priceSetting, ...payload },
        planVersion: this.version
      }
    });

    if (plan) {
      this.setPlan(plan);
      await activity.reloadActivity();
    }
  };

  @computed
  get title() {
    return this.plan?.title || '';
  }

  @computed
  get salesStatus() {
    return this.plan?.salesStatus || SalesStatus.On;
  }

  @computed
  get localizedTitles() {
    return toJS(this.plan?.localizedTitles) || [];
  }

  @computed
  get description() {
    return this.plan?.description || '';
  }

  @computed
  get localizedDescriptions() {
    return toJS(this.plan?.localizedDescriptions) || [];
  }

  @computed
  get images() {
    return toJS(this.plan?.images) || [];
  }

  @computed
  get planId() {
    return this.plan?.id || '';
  }

  @computed
  get voucherExpirationPeriod() {
    return toJS(this.plan?.voucherExpirationPeriod) || {};
  }
  @computed
  get checkinPoints() {
    return toJS(this.plan?.checkinPoints) || [];
  }

  @computed
  get checkoutPoints() {
    return toJS(this.plan?.checkoutPoints) || [];
  }

  @computed
  get itinerary() {
    return toJS(this.plan?.itinerary) || [];
  }
  @computed
  get pickupLocations() {
    return toJS(this.plan?.pickupLocations) || [];
  }

  @computed
  get dropoffLocations() {
    return toJS(this.plan?.dropoffLocations) || [];
  }

  @computed
  get bookingRule() {
    return toJS(this.plan?.bookingRule) || {};
  }

  @computed
  get startDate() {
    return this.plan?.startDate || '';
  }

  @computed
  get endDate() {
    return this.plan?.endDate || '';
  }

  @computed
  get minCapacity() {
    return this.plan?.minCapacity || 1;
  }

  @computed
  get maxCapacity() {
    return this.plan?.maxCapacity || 10;
  }

  @computed
  get faqs() {
    return toJS(this.plan?.faqs) || [];
  }

  @computed
  get checklists() {
    return toJS(this.plan?.checklists) || [];
  }

  @computed
  get additionalForms() {
    return toJS(this.plan?.additionalForms) || [];
  }

  @computed
  get startTimes() {
    return toJS(this.plan?.startTimes) || [];
  }

  @computed
  get priceSetting() {
    return toJS(this.plan?.priceSetting) || {};
  }

  @computed
  get priceSettingUnits() {
    return toJS(this.plan?.priceSetting?.units) || [];
  }

  @computed
  get boookingFieldInfo() {
    return toJS(this.plan?.boookingFieldInfo) || {};
  }

  @computed
  get loaded(): boolean {
    return !!this.planId;
  }

  @computed
  get version() {
    return this.plan?.planVersion;
  }

  @computed
  get administrativeFieldsPlanId() {
    return toJS(this.plan?.administrativeFields?.planId) || '';
  }

  @computed
  get bundleHideVoucher() {
    return toJS(this.plan?.administrativeFields?.bundleHideVoucher) || false;
  }

  @computed
  get allotmentSettingId() {
    return toJS(this.plan?.administrativeFields?.allotmentSettingId) || '';
  }

  @computed
  get backend() {
    return toJS(this.plan?.administrativeFields?.backendSetting?.backend) || '';
  }
  @computed
  get productCode() {
    return (
      toJS(this.plan?.administrativeFields?.backendSetting?.backendCode) || ''
    );
  }

  @computed
  get unitCodes() {
    return (
      toJS(this.plan?.administrativeFields?.backendSetting?.unitCodes) || []
    );
  }

  @computed
  get startTimeCodes() {
    return (
      toJS(this.plan?.administrativeFields?.backendSetting?.startTimeCodes) ||
      []
    );
  }
  @computed
  get expirationVisibilitySetting() {
    return toJS(this.plan?.expirationVisibilitySetting) || {};
  }
}

export default new Plan();
